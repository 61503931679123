import React, { useContext } from 'react';
import styled from 'styled-components';
import {
  MainSection as Section,
  Wrapper,
} from 'src/components/Sites/ServicesPages/shared/ServicesPages';
import SubTitle from 'src/components/Sites/ServicesPages/shared/SubTitle';
import InnerBox from 'src/components/Sites/ServicesPages/shared/InnerBox';
import Paragraph from 'src/components/Sites/ServicesPages/shared/Paragraph';
import Label from 'src/components/Sites/ServicesPages/shared/Label';
import List from 'src/components/shared/List/List';
import ImageWrapper from 'src/components/shared/ImageWrapper/ImageWrapper';
import { DedicatedAppsPageContext } from 'src/pages/dedykowane-aplikacje-i-systemy';
import ListItem from 'src/components/shared/List/ListItem/ListItem';
import { FadeIn, MoveIn } from 'src/components/shared/Animations/Animations';
import useLng from "src/hooks/useLng";

const StyledWrapper = styled(Wrapper)`
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: flex-end;

  @media screen and (max-width: 767px) {
    display: block;
  }
`;

const StyledInnerWrapper = styled(Wrapper)`
  @media screen and (max-width: 1199px) {
    display: block;
    padding: 0;
  }
`;

const StyledParagraph = styled(Paragraph)`
  margin-bottom: 7.6rem !important;

  @media screen and (max-width: 767px) {
    margin-bottom: 5rem !important;
  }
`;

const WebAppsTypesSection = () => {
  const { data } = useContext(DedicatedAppsPageContext);
  const { t } = useLng()
  const cvImage = data?.find(({ name }) => name === 'obraz cv').childImageSharp
    .fluid;

  return (
    <Section>
      <Wrapper nonGrid>
        <FadeIn>
          <Label>{t("sys15")}</Label>
        </FadeIn>
        <FadeIn>
          <StyledParagraph>
            {t("sys16")}
          </StyledParagraph>
        </FadeIn>
      </Wrapper>
      <StyledWrapper mw>
        <StyledInnerWrapper pl>
          <SubTitle smallerMb>
            {t("sys17")}
          </SubTitle>
          <InnerBox>
            <List>
              <ListItem>
                <strong>{t("sys18")}</strong> - {t("sys19")}
              </ListItem>
              <ListItem>
                <strong>{t("sys20")}</strong> - {t("sys21")}
              </ListItem>
              <ListItem>
                <strong>{t("sys22")}</strong> - {t("sys23")}
              </ListItem>
              <ListItem>
                <strong>{t("sys24")}</strong> - {t("sys25")}
              </ListItem>
              <ListItem>
                <strong>{t("sys26")}</strong> - {t("sys27")}
              </ListItem>
            </List>
          </InnerBox>
        </StyledInnerWrapper>
        <StyledInnerWrapper>
          <MoveIn>
            <ImageWrapper image={cvImage} alt="cv" m="13rem 0" />
          </MoveIn>
          <InnerBox pl>
            <List>
              <ListItem>
                <strong>{t("sys28")}</strong> - {t("sys29")}
              </ListItem>
              <ListItem>
                <strong>{t("sys30")}</strong> - {t("sys31")}
              </ListItem>
            </List>
          </InnerBox>
        </StyledInnerWrapper>
      </StyledWrapper>
    </Section>
  );
};

export default WebAppsTypesSection;
