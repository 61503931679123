import React, { createContext } from 'react';
import styled from 'styled-components';
import SEO from 'src/components/seo';
import  Layout  from 'src/layouts/Layout';
import SocialMedia from 'src/components/SocialMedia/SocialMedia';
import ImageWrapper from 'src/components/shared/ImageWrapper/ImageWrapper';
import {
  Section,
  MainSection,
  Wrapper,
} from 'src/components/Sites/ServicesPages/shared/ServicesPages';
import {
  HeaderParagraph,
  HeaderSection,
  HeaderTextWrapper,
  HeaderTitle,
} from 'src/components/Sites/ServicesPages/shared/Header';
import InnerBox from 'src/components/Sites/ServicesPages/shared/InnerBox';
import SubTitle from 'src/components/Sites/ServicesPages/shared/SubTitle';
import Paragraph from 'src/components/Sites/ServicesPages/shared/Paragraph';
import WebAppsTypesSection from 'src/components/Sites/ServicesPages/DedicatedAppsAndSystems/WebAppsTypesSection';
import WebAppsAndTechnologySection from 'src/components/Sites/ServicesPages/DedicatedAppsAndSystems/WebAppsAndTechnologySection';
import { graphql } from 'gatsby';
import { FadeIn } from 'src/components/shared/Animations/Animations';
import FadeLink from 'src/components/shared/navigations/FadeLink/FadeLink';
import useLng from  'src/hooks/useLng';

export const DedicatedAppsPageContext = createContext({});

const StyledImageWrapper = styled(ImageWrapper)`
  max-width: 50vw;
  width: 100%;
  flex-shrink: 0;

  @media screen and (max-width: 767px) {
    max-width: 100%;
  }
`;

const StyledHeaderSection = styled(Section)`
  @media screen and (max-width: 767px) {
    margin-bottom: 0;
  }
`;

const StyledSocialMedia = styled(SocialMedia)`
  position: absolute;
  left: 5rem;
  top: 7rem;
  padding: 4rem 7rem 0 0;

  @media screen and (max-width: 1199px) {
    left: 3rem;
  }

  @media screen and (max-width: 991px) {
    left: 2rem;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const StyledHeader = styled(HeaderSection)`
  display: flex;
  //grid-template-columns: 50% 50%;
  align-items: flex-start;

  @media screen and (max-width: 767px) {
    display: block;
  }
`;

const StyledWrapper = styled(Wrapper)`
  display: grid;
  grid-template-columns: 50% 50%;

  @media screen and (max-width: 767px) {
    display: block;
  }
`;

const StyledInnerBox = styled(InnerBox)`
  max-width: 80%;

  @media screen and (max-width: 1199px) {
    max-width: 100%;
  }

  @media screen and (max-width: 767px) {
    max-width: 100%;
  }
`;

const StyledSubTitle = styled(SubTitle)`
  margin-bottom: 10rem;

  @media screen and (max-width: 767px) {
    margin-bottom: 5rem;
  }
`;

const StyledParagraph = styled(Paragraph)`
  margin-bottom: 3rem !important;
`;

const StyledHeaderTextWrapper = styled(HeaderTextWrapper)`
  flex-basis: 50%;
`;

const StyledRightHeaderSection = styled.div`
  max-width: 50vw;
  width: 100%;

  @media screen and (max-width: 767px) {
    max-width: unset;
  }
`;

const StyledFadeLink = styled(FadeLink)`
  font-size: 2.2rem;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`;

const DedicatedAppsAndSystems = ({
  location: { pathname: path },
  transitionStatus,
  entry,
  exit,
  data,
}) => {
  const headingImage = data.allFile.nodes.find(
    ({ name }) => name === 'obraz z symbolem dedykowanych aplikacji',
  ).childImageSharp.fluid;
  const { t } = useLng();
  return (
    <Layout
      path={path}
      id="dedykowane-aplikacje"
      nonBgc
      transitionStatus={transitionStatus}
      entry={entry}
      exit={exit}
    >
      <SEO title={t("sysSeo")} />
      <StyledHeaderSection nonGrid site first>
        <StyledHeader pl>
          <StyledHeaderTextWrapper>
            <StyledSocialMedia />
            <div>
              <HeaderTitle small>
                {t("sys")}
              </HeaderTitle>
              <HeaderParagraph big>{t("sys2")}</HeaderParagraph>
              <HeaderParagraph>
                {t("sys3")}
              </HeaderParagraph>
            </div>
          </StyledHeaderTextWrapper>
          <StyledRightHeaderSection>
            <StyledImageWrapper
              image={headingImage}
              alt="ikona aplikacji dedykowanych"
            />
            <InnerBox bigPadding>
              <HeaderParagraph big>{t("sys4")}</HeaderParagraph>
              <HeaderParagraph>
                {t("sys5")}
              </HeaderParagraph>
            </InnerBox>
          </StyledRightHeaderSection>
        </StyledHeader>
      </StyledHeaderSection>
      <Wrapper nonGrid>
        <FadeIn>
          <StyledSubTitle>
            {t("sys6")}
          </StyledSubTitle>
        </FadeIn>
      </Wrapper>
      <MainSection as="div">
        <StyledWrapper>
          <InnerBox>
            <FadeIn>
              <Paragraph small bold>
                {t("sys7")}
              </Paragraph>
            </FadeIn>
            <FadeIn>
              <Paragraph small>
                {t("8")}
              </Paragraph>
            </FadeIn>
            <FadeIn>
              <Paragraph small bold>
                {t("sys9")}
              </Paragraph>
            </FadeIn>
            <FadeIn>
              <StyledParagraph small>
                {t("sys10")}
              </StyledParagraph>
            </FadeIn>
          </InnerBox>
          <StyledInnerBox pl mw>
            <FadeIn>
              <Paragraph small bold>
                {t("sys11")}
              </Paragraph>
            </FadeIn>
            <FadeIn>
              <Paragraph small>
                {t("sys12")}
              </Paragraph>
            </FadeIn>
            <FadeIn>
              <Paragraph small bold>
                {t("sys13")}
              </Paragraph>
            </FadeIn>
            <FadeIn>
              <Paragraph small>
                {t("sys14")}
              </Paragraph>
            </FadeIn>
          </StyledInnerBox>
        </StyledWrapper>
      </MainSection>
      <DedicatedAppsPageContext.Provider value={{ data: data.allFile.nodes }}>
        <>
          <WebAppsTypesSection />
          <WebAppsAndTechnologySection />
        </>
      </DedicatedAppsPageContext.Provider>
      <Wrapper nonGrid>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <StyledFadeLink to="/projekty" stylish>
            {t("naszeRealizacje")}
          </StyledFadeLink>
        </div>
      </Wrapper>
    </Layout>
  );
};

export const dedicatedAppsQuery = graphql`
  query DedicatedAppsQuery {
    allFile(filter: { relativeDirectory: { eq: "dedicated-apps" } }) {
      nodes {
        name
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`;

export default DedicatedAppsAndSystems;
