import React, { useContext } from 'react';
import styled from 'styled-components';
import {
  MainSection as Section,
  Wrapper,
} from 'src/components/Sites/ServicesPages/shared/ServicesPages';
import SubTitle from 'src/components/Sites/ServicesPages/shared/SubTitle';
import InnerBox from 'src/components/Sites/ServicesPages/shared/InnerBox';
import Paragraph from 'src/components/Sites/ServicesPages/shared/Paragraph';
import Label from 'src/components/Sites/ServicesPages/shared/Label';
import List from 'src/components/shared/List/List';
import Slider from 'src/components/Sites/ServicesPages/shared/Slider';
import ColoredBox from 'src/components/Sites/ServicesPages/shared/ColoredBox';
import { DedicatedAppsPageContext } from 'src/pages/dedykowane-aplikacje-i-systemy';
import { FadeIn, MoveIn } from 'src/components/shared/Animations/Animations';
import ImageWrapper from 'src/components/shared/ImageWrapper/ImageWrapper';
import useLng from "src/hooks/useLng";

const StyledWrapper = styled(Wrapper)`
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: ${({ flexStart }) => (flexStart ? 'flex-start' : 'flex-end')};

  @media screen and (max-width: 767px) {
    display: block;
  }
`;

const StyledInnerWrapper = styled(Wrapper)`
  @media screen and (max-width: 1199px) {
    display: block;
    padding: 0;
  }
`;

const StyledSubTitle = styled(SubTitle)`
  margin-bottom: 3rem;
`;

const StyledList = styled(List)`
  margin-bottom: 3rem;
`;

const StyledSlider = styled(Slider)`
  max-width: 100%;
  padding-right: 1rem;
  margin-bottom: 5rem;

  @media screen and (max-width: 767px) {
    padding-right: 0;
  }
`;

const frontendData = [
  'HTML,',
  'CSS,',
  'JavaScript',
];

const backendData = ['PHP, Laravel, Symfony,', 'JavaScript,', 'Java.'];

const WebAppsAndTechnologySection = () => {
  const { data } = useContext(DedicatedAppsPageContext);
  const { t } = useLng()
  const aplikacjaWebowaImg = data?.find(
    ({ name }) => name === 'aplikacja webowa',
  );
  const dedykowaneAplikacje1Img = data?.find(
    ({ name }) => name === 'dedykowane aplikacje',
  );

  return (
    <Section>
      <Wrapper nonGrid>
        <FadeIn>
          <Label>{t("sys32")}</Label>
        </FadeIn>
        <FadeIn>
          <Paragraph>{t("sys33")}
          </Paragraph>
        </FadeIn>
      </Wrapper>
      <StyledWrapper mw>
        <StyledInnerWrapper pl>
          <InnerBox>
            <StyledSubTitle>{t("sys34")}</StyledSubTitle>
            <Paragraph small>
              {t("sys35")}
            </Paragraph>
            <StyledList items={frontendData} />
            <Paragraph small bold>
              {t("sys36")}
            </Paragraph>
            <List items={backendData} />
          </InnerBox>
        </StyledInnerWrapper>
        <StyledInnerWrapper>
          <MoveIn>
            <ImageWrapper
              image={aplikacjaWebowaImg?.childImageSharp.fluid ?? ""}
              alt="przykład aplikacji webowej"
            />
          </MoveIn>
        </StyledInnerWrapper>
      </StyledWrapper>
      <Wrapper nonGrid>
        <FadeIn up>
          <Paragraph big>
            {t("sys37")}
          </Paragraph>
        </FadeIn>
      </Wrapper>
      <Wrapper nonGrid>
        <FadeIn>
          <StyledSubTitle>{t("sys38")}</StyledSubTitle>
        </FadeIn>
        <FadeIn>
          <Paragraph small>
            {t("sys39")}
            <br />{t("sys40")}
          </Paragraph>
        </FadeIn>
      </Wrapper>
      <StyledWrapper mw flexStart>
        <MoveIn>
          <ImageWrapper
            image={dedykowaneAplikacje1Img?.childImageSharp.fluid ?? ""}
            alt="dedykowana aplikacja"
            imgStyle={{ height: '100%', maxHeight: '46rem' }}
          />
        </MoveIn>
        <FadeIn right>
          <ColoredBox>
            <FadeIn animationToProp={{ delay: 1 }}>
              <SubTitle smallerMb>
                {t("sys41")}
              </SubTitle>
              <Paragraph small bold>
                {t("sys42")}

              </Paragraph>
              <InnerBox>
                <Paragraph small>
                  {t("sys43")}

                </Paragraph>
              </InnerBox>
            </FadeIn>
          </ColoredBox>
        </FadeIn>
      </StyledWrapper>
      <StyledWrapper mw flexStart>
        <StyledInnerWrapper pl last>
          <InnerBox>
            <FadeIn>
              <Paragraph small bold>
                {t("sys44")}
              </Paragraph>
            </FadeIn>
            <FadeIn>
              <Paragraph small>
                {t("sys45")}
              </Paragraph>
            </FadeIn>
            <FadeIn>
              <Paragraph small bold>
                {t("sys46")}
              </Paragraph>
            </FadeIn>
            <FadeIn>
              <Paragraph small>
                {t("sys47")}
              </Paragraph>
            </FadeIn>
            <FadeIn>
              <Paragraph small bold>
                {t("sys48")}
              </Paragraph>
            </FadeIn>
            <FadeIn>
              <Paragraph small>
                {t("sys49")}
              </Paragraph>
            </FadeIn>
          </InnerBox>
        </StyledInnerWrapper>
        <StyledInnerWrapper pr>
          <InnerBox pl>
            <FadeIn>
              <Paragraph small bold>
                {t("sys50")}
              </Paragraph>
            </FadeIn>
            <FadeIn>
              <Paragraph small>
                {t("sys51")}

              </Paragraph>
            </FadeIn>
            <FadeIn>
              <Paragraph small bold>
                {t("sys52")}
              </Paragraph>
            </FadeIn>
            <FadeIn>
              <Paragraph small>
                {t("sys53")}

              </Paragraph>
            </FadeIn>
            <FadeIn>
              <Paragraph small bold>
                {t("sys54")}
              </Paragraph>
            </FadeIn>
            <FadeIn>
              <Paragraph small>
                {t("sys55")}
              </Paragraph>
            </FadeIn>
          </InnerBox>
        </StyledInnerWrapper>
      </StyledWrapper>
    </Section>
  );
};

export default WebAppsAndTechnologySection;
